import * as React from "react";
import Layout from "../components/layout";

// markup
const IndexPage = () => {
  const regexp =
    /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

  const ss = (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const mail = document.getElementById("email").value;
    const message = document.getElementById("message").value;

    if (name.length === 0) {
      alert("ユーザー名を入力してください");
      return;
    }

    if (mail.length === 0) {
      alert("メールアドレスを入力してください");
      return;
    }

    if (regexp.test(mail) === false) {
      alert("有効なメールアドレスを入力してください");
      return;
    }

    if (message.length === 0) {
      alert("お問い合わせ内容を入力してください");
      return;
    }

    const formSubmit = document.querySelector("#formsubmit");
    formSubmit.setAttribute("disabled", "");

    const data = {
      name: name,
      email: mail,
      body: message,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    fetch(
      "https://76yvpfjzh5.execute-api.ap-northeast-1.amazonaws.com/production/request",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => {
        alert("送信しました");
        window.location.reload();
      }); // set dat
  };

  return (
    <Layout
      title="CO Record & journal | 毎日のウォーキングがゲーム感覚で楽しめる歩数計アプリ"
      policyStyle={false}
      showHeader={true}
      isTop={true}
    >
      <main id="id-main" className="noto-body">
        <section id="page-top-end" className="back-red">
          <div className="mv-service-end">
            <div className="wrap_middle-service-end">
              <h2>
                【iOS/Android版】CO Recourd & Journal サービス終了について
              </h2>
              <div className="notice">
                <p>
                  いつもCO Recourd &
                  Journalをご利用いただきありがとうございます。
                </p>
                <p>
                  誠に勝手ながら、CO Recourd & Journalは
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    2023年12月22日(金) 12:00
                  </span>
                  をもちまして終了することとなりました。
                </p>
                <p>
                  これまでご愛顧いただきましたこと、心より感謝申し上げます。
                </p>
                <p>サービス終了に伴い、以下の点にご注意ください。</p>
                <br />
                <p>
                  ・サービス終了後はすべての機能がご利用いただけなくなります。
                </p>
                <p>
                  ・ご登録いただいたデータは、サービス終了日をもって削除されます。
                </p>

                <a href="service-end-faq/">
                  その他サービス終了に伴う注意点はこちら
                </a>
                <br />
                <br />
                <p>
                  ご不明点がございましたら、お問い合わせページよりご連絡ください。
                </p>

                <p>
                  突然のお知らせとなり、ご迷惑をおかけしますことをお詫び申し上げます。
                </p>
                <p>今後とも、どうぞよろしくお願い申し上げます</p>
              </div>
            </div>
          </div>
        </section>
        <section id="page-top" className="back-red">
          <div className="fv-content-box">
            <div className="flex-box flex-img-left">
              <div className="text-box">
                <h2 className="app-title">
                  <img
                    src="./img/fv_logo-main01.png"
                    alt="CO Record & journal"
                  />
                </h2>
                <p className="read-text">
                  毎日のウォーキングが
                  <br />
                  ゲーム感覚で楽しめる
                </p>
              </div>
              <div className="img-box">
                <img
                  className="img-width img-02"
                  src="./img/fv_img-02.png"
                  alt="CO Record & jurnal 画面イメージ-2"
                />
                <img
                  className="img-width img-01"
                  src="./img/fv_img-01.png"
                  alt="CO Record & jurnal 画面イメージ-1"
                />
                <img
                  className="img-03"
                  src="./img/fv_hukidasi01.png"
                  alt="歩くに連れてマップが開く！"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="point3">
          <div className="contents">
            <div className="flex-box flex-three-column">
              <div className="list-box">
                <p>
                  面倒な運動も
                  <br />
                  ゲーム感覚で
                  <br />
                  ウォーキングが楽しめる！
                </p>
              </div>
              <div className="list-box">
                <p>
                  普段どおりスマホを
                  <br />
                  持ち歩くだけで使える！
                </p>
              </div>
              <div className="list-box">
                <p>
                  運動不足も
                  <br />
                  これ1つで解消！
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="service">
          <div className="contents">
            <h2>サービスの特徴</h2>
          </div>
          <div className="back-1">
            <div className="contents">
              <div className="flex-box flex-img-left">
                <div className="text-box">
                  <h3>
                    デイリー＆ウィークリーの
                    <br />
                    チャレンジクリアを
                    <br className="pc-none" />
                    目指そう！
                  </h3>
                  <p>
                    デイリー＆ウィークリーで設定されるさまざまなチャレンジを達成してアイテムを獲得しよう。
                  </p>
                </div>
                <div className="img-box">
                  <img
                    src="./img/screen_app-01.png"
                    alt="チャレンジクリアを目指そう！| アプリイメージ01"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="back-2">
            <div className="contents">
              <div className="flex-box flex-img-left">
                <div className="text-box">
                  <h3>
                    チャレンジクリアで
                    <br />
                    アイテムを獲得！
                  </h3>
                  <p>
                    チャレンジクリアで獲得したアイテムを使うとさらに効率よく経験値を獲得できます。
                  </p>
                </div>
                <div className="img-box">
                  <img
                    src="./img/screen_app-02.png"
                    alt="チャレンジクリアでアイテムを獲得 | アプリイメージ02"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="back-3">
            <div className="contents">
              <div className="flex-box flex-img-left">
                <div className="text-box">
                  <h3>
                    カテゴリごとの
                    <br />
                    ランキング上位を目指そう！
                  </h3>
                  <p>
                    歩数、パネル獲得数、距離ごとにランキングを表示。グループを作って競うこともできます。
                  </p>
                </div>
                <div className="img-box">
                  <img
                    src="./img/screen_app-03.png"
                    alt="カテゴリごとのランキング｜アプリイメージ03"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="character">
          <div className="contents">
            <h2>
              歩くごとにキャラクターが
              <br className="pc-none" />
              言葉をおぼえていく！
            </h2>
            <p className="txt-center">
              チャレンジクリアや経験値を貯めると
              <br />
              キャラクターの CoCo が<br className="pc-none" />
              言葉をおぼえていきます。
            </p>
            <div className="flex-box flex-three-column">
              <div className="list-box">
                <img src="./img/coco_img-01.png" alt="CoCo１" />
              </div>
              <div className="list-box">
                <img src="./img/coco_img-02.png" alt="CoCo２" />
              </div>
              <div className="list-box">
                <img src="./img/coco_img-03.png" alt="CoCo３" />
              </div>
            </div>
          </div>
        </section>

        <section id="mail-form">
          <div className="contents">
            <h2>
              サービスに関する
              <br className="pc-none" />
              お問い合わせ
            </h2>

            <form action="" method="post">
              <div className="form-block form-name">
                <label htmlFor="name" className="hissu">
                  ユーザー名
                </label>
                <input type="text" id="name" name="name" />
              </div>

              <div className="form-block form-email">
                <label htmlFor="email" className="hissu">
                  メールアドレス
                </label>
                <input type="mail" id="email" name="email" />
              </div>

              <div className="form-block form-toi">
                <label htmlFor="message" className="hissu">
                  お問い合わせ内容
                </label>
                <textarea id="message" name="message"></textarea>
              </div>
              <div className="check-submit-block">
                <div className="agree-code-box">
                  <p>
                    当サービスのプライバシーポリシーに
                    <br className="sp-none" />
                    ご同意いただける方は「同意する」を選択してください
                  </p>
                </div>
                <iframe src="policyif/index.html"></iframe>
                <label className="agree-label" htmlFor="agree">
                  <input
                    type="checkbox"
                    id="agree"
                    name="agree"
                    value="同意する"
                  />
                  同意する
                </label>
              </div>

              <div id="form-button">
                <input
                  id="formsubmit"
                  type="submit"
                  value="送信する"
                  onClick={ss}
                />
              </div>
            </form>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
